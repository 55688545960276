import React from 'react';
import { Box } from '@mui/material';
import { TreeDataGrid } from 'react-data-grid';
import { sortRows, rowGrouper } from "./tableFunctions";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { kiteAPI } from "../../utils/axiosInstance";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const columns = [
    {
        key: "country",
        resizable: true,
        sortable: true,
        minWidth: 75,
        type: "string",
        renderCell: (c)=> (<span style={{textTransform: 'capitalize'}}>{(c.row.country ?? "")}</span>),
        name: "Country",
    },
    {
        key: "eventDate",
        resizable: true,
        sortable: true,
        minWidth: 110,
        renderGroupCell: (row) => {
            const r = row.childRows[0]
            return <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{row.groupKey} ({r.eventDay}) {row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
        },
        sortComparator: (a, b) => new Date(a)-(new Date(b)),
        name: "Date",
    },
    {
        key: "eventTime",
        resizable: true,
        sortable: true,
        minWidth: 75,
        // renderCell: (c)=> (<span >{c.row.eventTime && c.row.eventTime !== "None"? moment(c.row.eventTime, "HH:mm:ss").format("hh:mm A") : "-"}</span>),
        sortComparator: (a, b) => {
            if (!a) return 1;
            if (!b) return -1;
      
            return new Date(`1970-01-01T${a}Z`) - new Date(`1970-01-01T${b}Z`);
        },
        name: "Time",
    },
    {
        key: "description",
        resizable: true,
        sortable: true,
        minWidth: 200,
        type: "string",
        renderCell: (c)=> (<span>{`${c.row.description}${c.row.reference ? ` - ${c.row.reference}` : ""}`} </span>),
        name: "Event",

    }
]

export default function EcoCalendar() {
    const [rows, setRows] = React.useState([]);
    const [expandedGroupIds, setExpandedGroupIds] = React.useState(new Set([new Date().toISOString().split('T')[0]]));
    const [list, setList] = React.useState([]);
    const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
    // const [fromDate, setFromDate] = React.useState(new Date(new Date().setDate(1)));
    // const [toDate, setToDate] = React.useState(new Date());
    const [sortColumn, setSortColumn] = React.useState([{
        "columnKey": "eventDate",
        "direction": "ASC"
    }]);

    const handleSort = (s) => {
        setSortColumn(s.map((s1)=>{
            const ind = columns.findIndex((i)=> s1.columnKey === i.key)
            if (ind !== -1){
                return(columns[ind].sortComparator ?{...s1, sortComparator: columns[ind].sortComparator} : s1)
            }else{
                return(s1);
            }
        }));
    };

    const getData = React.useCallback(async () => {
        const response = await kiteAPI.get(`events/get/global_events?fromDate=${fromDate}&toDate=${toDate}`);
        if (response.status === 200 && response.data.data){
            setList(response.data.data)
        }else{
            setList([])
        }
    }, [fromDate, toDate])

    React.useEffect(()=>{
        getData()
    }, [getData])

    React.useEffect(()=>{
        setRows(sortColumn.length ? sortRows(list, sortColumn) : list);
    },[sortColumn, list]);

  return (
    <Box sx={{width: '95%', margin: '0px auto'}}>
        {/* <Box padding='.8rem' width='100%' display='flex' justifyContent='flex-end' alignItems='center' flexDirection='row'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker slotProps={{ textField: { size: 'small' } }} onChange={(date) => setFromDate(date)} sx={{paddingX:.5}} portalId="root-portal" startDate={fromDate} endDate={toDate} autoComplete='false' label="Start Date" value={fromDate} />
            <DatePicker slotProps={{ textField: { size: 'small' } }} onChange={(date) => { if(!fromDate){ setFromDate(new Date()) } setToDate(date)}} portalId="root-portal" sx={{paddingX:.5}} startDate={fromDate} endDate={toDate} minDate={fromDate} value={toDate} label="End Date" autoComplete='false' />
            </LocalizationProvider>
        </Box> */}
        {
            rows.length ?
                <TreeDataGrid style={{height: "auto", textAlign: 'center'}} columns={columns} groupBy={['eventDate']} rowGrouper={rowGrouper} sortColumns={sortColumn} onSortColumnsChange={handleSort} expandedGroupIds={expandedGroupIds} onExpandedGroupIdsChange={setExpandedGroupIds} rows={rows}/>
            : <Box sx={{textAlign: 'center', fontSize: '2rem'}}>No F&O Stocks...</Box>
        }
    </Box>
  )
}