import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {apiUser} from '../../../utils/axiosInstance';
import { createAlert } from "../alert/TransitionAlertsSlice";
import { setLoader } from "../loader/loaderSlice";
import { setTab } from '../tabs/tabSlice';

const initialState = {
  currentUser: "",
  loginData: null,
  loggedIn: null,
  marktPositions: []
};

export const loginUser = createAsyncThunk('user/login', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await apiUser.post('user/login', req);
  dispatch(setLoader(false))
  if(response.data.message)
    dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  return response.data;
});
export const googleLoginUser = createAsyncThunk('user/google-signIn', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await apiUser.post('user/google-signIn', req);
  dispatch(setLoader(false))
  if(response.data.message)
    dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  return response.data;
});
export const getLoginUser = createAsyncThunk('user/current', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await apiUser.get('user/current');
  if (response.data.setupPassword){
    dispatch(setTab('chg_pw'))
  }
  dispatch(setLoader(false))
  return response.data;
});
export const getCheckLogin = createAsyncThunk('user/auth-check', async (req, {dispatch}) => {
  // dispatch(setLoader(true))
  const response = await apiUser.get('user/auth-check');
  // dispatch(setLoader(false))
  return response.data;
});
export const logoutUser = createAsyncThunk('user/logoutUser', async (req, {dispatch}) => {
  // dispatch(setLoader(true))
  const response = await apiUser.post('user/logout', {});
  return response.data;
});
export const getMarketPositions  = createAsyncThunk('user/marketPositions', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await apiUser.get('order/get-position');
  dispatch(setLoader(false))
  return response.data;
});
export const delMarketPositions = createAsyncThunk('user/delMarketPositions', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const {instrument} = req;
  const response = await apiUser.delete(`order/del-position/${instrument}`);
  dispatch(getMarketPositions())
  dispatch(setLoader(false))
  if(response.data.message)
    dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 1500}))
  return response.data;
});
export const placeOrders  = createAsyncThunk('user/placeOrders', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await apiUser.post('order/create-orders', req);
  dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  dispatch(setLoader(false))
  return response.data;
});
export const placeOrder  = createAsyncThunk('user/placeOrder', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await apiUser.post('order/create', req);
  dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  dispatch(setLoader(false))
  return response.data;
});

const userSlice  = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, payload) => {
      state.currentUser = payload.payload;
    },
    logout: (state) => {
      state.loggedIn = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loggedIn = action.payload.success;
      })
      .addCase(loginUser.rejected, (state) => {
        state.loggedIn = false;
      })
      .addCase(googleLoginUser.fulfilled, (state, action) => {
        state.loggedIn = action.payload.success;
      })
      .addCase(googleLoginUser.rejected, (state) => {
        state.loggedIn = false;
      })
      .addCase(getMarketPositions.fulfilled, (state, action) => {
        if (action.payload && action.payload.success && action.payload.data && Array.isArray(action.payload.data)){
          state.marktPositions = [...action.payload.data.map((d)=> {return {...d, id:d.instrument}})];
        }
      })
      .addCase(placeOrders.fulfilled, (state, action) => {
        if (action.payload && action.payload.success && action.payload.data  && Array.isArray(action.payload.data)){

          const instruments = action.payload.data;
          const marktPositions = state.marktPositions;

          for (const instrument of instruments){

            const indexToUpdate = marktPositions.findIndex( v => v.instrument === instrument.instrument);
  
            if (indexToUpdate !== -1){
              marktPositions[indexToUpdate] = instrument
            }else{
              marktPositions.push(instrument)
            }
          }

          state.marktPositions = [ ...marktPositions.map((d)=> {return {...d, id:d.instrument}})]
        }
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        if (action.payload && action.payload.success && action.payload.data){

          const instrument = action.payload.data;
          const marktPositions = state.marktPositions;

            const indexToUpdate = marktPositions.findIndex( v => v.instrument === instrument.instrument);
  
            if (indexToUpdate !== -1){
              marktPositions[indexToUpdate] = instrument
            }else{
              marktPositions.push(instrument)
            }

          state.marktPositions = [ ...marktPositions.map((d)=> {return {...d, id:d.instrument}})]
        }
      })
      .addCase(getLoginUser.fulfilled, (state, action) => {
        if(action?.payload?.id){
          state.loginData = action.payload;
        }else{
          state.loginData = null;
          state.loggedIn = false;
        }
      })
      .addCase(getLoginUser.rejected, (state, action) => {
        if (action.error.message.includes('401')){
          state.loginData = null;
        }
      })
      .addCase(getCheckLogin.fulfilled, (state, action) => {

        if(action?.payload?.logged_in_as){
          state.loggedIn = true;
        }else{
          state.loginData = null;
          state.loggedIn = false;
        }
      })
      .addCase(getCheckLogin.rejected, (state, action) => {
        if (action.error.message.includes('401')){
          state.loggedIn = false;
          state.loginData = null;
        }
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loginData = null;
        state.loggedIn = false;
      })
  },
});

export const { setCurrentUser, logout } = userSlice.actions 

export default userSlice.reducer;
