import React from 'react';
import { Box } from '@mui/material';
import { getOiList } from "../../features/slices/oi/oiSlice";
import { useDispatch, useSelector  } from 'react-redux';
import moment from 'moment-timezone';
import DataGrid from 'react-data-grid';
import { sortRows } from "./tableFunctions";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

const columns = [
    {
        key: "id",
        resizable: true,
        sortable: true,
        minWidth: 155,
        type: "string",
        name: "Symbol",
    },
    {
        key: "pri",
        resizable: true,
        sortable: true,
        minWidth: 155,
        type: "number",
        name: "Price",
    },
    {
        key: "pri_c",
        resizable: true,
        sortable: true,
        minWidth: 155,
        type: "number",
        name: "Price Chg (%)",
    },
    {
        key: "l_oi",
        resizable: true,
        sortable: true,
        minWidth: 155,
        type: "number",
        name: "Pre MWPL (%)",
    },
    {
        key: "oi",
        resizable: true,
        sortable: true,
        minWidth: 155,
        type: "number",
        name: "Curr MWPL (%)",
    },
    {
        key: "chg",
        resizable: true,
        sortable: true,
        minWidth: 155,
        type: "number",
        name: "Change MWPL (%)",
    },
    {
        key: "ban",
        resizable: true,
        sortable: true,
        minWidth: 155,
        renderCell: (c)=> (<>{c.row.ban? <CheckCircleSharpIcon className='textclr-red'/> : <CancelSharpIcon className='textclr-green' />}</>),
        name: "In Ban",
    },
]

export default function MWPLTable({value}) {
    const dispatch = useDispatch();
    // const gridRef = useGridApiRef();
    const [rows, setRows] = React.useState([]);
    const [sortColumn, setSortColumn] = React.useState([]);
    const oiData = useSelector((state) => state.oi.oiList.list);

    const handleSort = (sortColumns, setSortColumn) => {
        setSortColumn([...sortColumns]);
    };

    React.useEffect(()=>{
        const data = (value === 'all'? oiData: value === 'ban'? oiData.filter((v) => v.ban) : value === 'banin' ? oiData.filter((v) => !v.ban && v.oi > 90 && (v.oi - v.l_oi > 5)) : value === 'banout' ? oiData.filter((v) => v.ban && v.oi < 85 && (v.l_oi - v.oi > 5)) : []).map((d)=> ({...d, chg: Number((d.oi - d.l_oi).toFixed(2))}));
        setRows(sortColumn.length ? sortRows(data, sortColumn) : data);
    },[oiData, value, sortColumn]);

    React.useEffect(()=>{
        dispatch(getOiList());
        const interval = setInterval(() => {

            if (document.visibilityState === 'visible') {
              const now = moment().tz("Asia/Kolkata");
              const dayOfWeek = now.isoWeekday(); // 1: Monday, ..., 7: Sunday
              const start = moment().tz("Asia/Kolkata").set({ hour: 9, minute: 0, second: 0 });
              const end = moment().tz("Asia/Kolkata").set({ hour: 15, minute: 30, second: 0 });
              
              if (dayOfWeek >= 1 && dayOfWeek <= 5 && now.isBetween(start, end)) {
                dispatch(getOiList());
              }
            }
          }, 30000); // Update count every second
          return () =>{
            clearInterval(interval);
          }
    },[dispatch]);

  return (
    <Box sx={{width: '95%', margin: '0px auto'}}>
        {
            rows.length ? 
                <DataGrid
                    style={{fontSize: '1.1rem', fontWeight: 400, height:'80vh', textAlign: 'center'}} 
                    rowHeight={30}
                    headerRowHeight={35}
                    sortColumns={sortColumn} onSortColumnsChange={(r) => handleSort(r, setSortColumn)}
                    rowClass={(p => p.ban ? `textclr-red` : `textclr-green`)}
                    columns={columns}
                    rows={rows}
                />
            : <Box sx={{textAlign: 'center', fontSize: '2rem'}}>No Stocks...</Box>
        }
    </Box>
  )
}