import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import MWPLTable from "../../Tables/MWPLTable";

export default function MWPL() {
    const [value, setValue] = React.useState('all');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return (
    <Box >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb:2 }}>
            <Tabs centered value={value} onChange={handleChange} aria-label="MWPL tabs">
                <Tab label="All" value={'all'}/>
                <Tab label="In Ban" value={'ban'}/>
                <Tab label="Probable Incoming Ban" value={'banin'}/>
                <Tab label="Probable Outcoming Ban" value={'banout'}/>
            </Tabs>
        </Box>
        <MWPLTable value={value} />
    </Box>
  )
}